import { combineReducers } from 'redux'

import { authReducer } from './auth/reducer'

const appReducer = combineReducers({
  auth: authReducer,
})

export const Reducers = (state, action) => {
  // reset store in logout
  if (action.type === 'LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}
