import styled from 'styled-components'

export default styled.div`
  display: flex;

  .PDF-Page > canvas {
    max-width: 100% !important;
    height: auto !important;
  }

  .files-container {
    @media print {
      & {
        display: none !important;
      }
    }

    width: 50%;

    .files-btns-container {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;

      .btn-input-file-container {
        display: flex;
        flex-direction: column;
      }

      .btn-item-text {
        font-size: 1.5rem;
        font-weight: bold;
        margin-right: 10px;
      }

      .btn-item {
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    };

    .files-files-container {
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      position: relative;

      .img-btn-delete-container {
        position: absolute;
        right: 0;
        margin: 5px;
        opacity: 0.7;
        z-index: 1;
      }
    }
  };

  .content-container {
    @media print {
      & {
        width: 100%;
      }
    }

    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 6rem;

    .content-btns-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .values-container {
      width: 100%;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  };
`
