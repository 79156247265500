import _ from 'lodash'
import axios from './axiosCustom'

const HOST = process.env.REACT_APP_IR_MAIN_API

const headers = (apiKey) => ({
  //'x-api-key': apiKey,
  'Authorization': apiKey,
  'Content-type': 'application/json',
})

export async function postFile(
  apiKey,
  userId,
  {
    token,
    name,
    base64,
    fileType,
    filePosition,
    totalRequests,
  },
) {
  let data = {
    user_id: userId,
    token: token,
    name: name,
    base64: base64,
    file_type: fileType,
    file_position: filePosition,
    total_requests: totalRequests,
  }

  data = _.pickBy(data, (val) => !_.isNil(val))

  const res = await axios({
    method: 'POST',
    url: `${HOST}/v1/ocr`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function getConsults(apiKey, userId, options) {
  const {
    page,
  } = options

  let queryString = `&user_id=${userId}`
  queryString += `&page=${page || 1}`

  const res = await axios({
    method: 'GET',
    url: `${HOST}/v1/ocrs?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function getConsultResponse(apiKey, userId, token) {
  let queryString = `&user_id=${userId}`
  queryString += `&token=${token}`

  const res = await axios({
    method: 'GET',
    url: `${HOST}/v1/ocr/result?${queryString}`,
    headers: headers(apiKey),
  })

  return res
}

export async function postGenerateCsv(apiKey, userId, content) {
  const data = {
    user_id: userId,
    ...content,
  }

  const res = await axios({
    method: 'POST',
    url: `${HOST}/v1/ocr/export/csv`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}

export async function postConclude(apiKey, userId, token, content) {
  const data = {
     user_id: userId,
    ...content,
  }

  const res = await axios({
    method: 'PUT',
    url: `${HOST}/v1/ocr/conclude/${token}`,
    headers: headers(apiKey),
    data: data,
  })

  return res
}
