import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import classnames from 'classnames'

import { Icons } from 'ui/components'

const Container = styled.div`
  .row {
    color: ${(props) => props.theme.palette.gray[600]};
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &.selected {
      color: black;
    }

    &.disabled {
      opacity: 0.5;
      cursor: initial;
    }

    .label {
      margin-left: 1rem;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
`

export default function ({ label, onClick, value, className, disabled = false }) {
  const withState = !_.isBoolean(value)

  let selected = withState ? false : value
  let setSelected = null
  if (withState) {
    [selected, setSelected] = useState(false)
  }

  const handleClick = () => {
    if (disabled) return

    if (withState) setSelected(!selected)
    if (onClick) onClick(!selected)
  }

  const classSelected = selected ? 'selected' : null
  const classDisabled = disabled ? 'disabled' : null

  return (
    <Container className={className}>
      <div
        className={classnames('row', classSelected, classDisabled)}
        onClick={handleClick}
      >
        <div className="icon">
          <Icons
            icon={selected ? 'radioFilled' : 'radio'}
          />
        </div>
        {label && <div className="label">{label}</div>}
      </div>
    </Container>
  )
}
