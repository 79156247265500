import styled from 'styled-components'

export default styled.div`
  display: flex;

  .item-text {
    font-size: 12px;
  }

  .button-open-drawer {
    position: absolute;
  }
`
