import React from 'react'
import { withStyles, useTheme } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'

import {
  AppBar,
  Toolbar,
  Popper,
  Grow,
  Paper,
  Typography,
  MenuItem,
  MenuList,
  ClickAwayListener,
  useMediaQuery,
  IconButton,
  Drawer,
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  InsertEmoticon,
  Cancel,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'
import { logout } from 'redux/auth/actions'

import styles from './styles'

const MainNavbar = ({ component: Component }) => {
  const classes = styles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const dispatch = useDispatch()
  const theme = useTheme()

  const name = useSelector(state => state.auth.name)
  const [openDrawer, setOpenDrawer] = React.useState(false)

  function handleDrawerOpen() {
    setOpenDrawer(true)
  }

  function handleDrawerClose() {
    setOpenDrawer(false)
  }

  function handleClose(event) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }

    setOpen(false)
  }

  const renderMenu = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      keepMounted
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                classes={{
                  root: classes.dropdown,
                }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

  const sectionUser = () => (
    <div className={classes.sectionUser}>
      <ul className={classes.listNav}>
        <li className={classes.listNavItemUsername}>
          <InsertEmoticon />
          <Typography color="inherit" className={classes.menuItem}>{name.toUpperCase()}</Typography>
          <Typography color="inherit" className={classes.dateTime}>{`Data da Impressão: ${(new Date()).toLocaleString('PT-BR')}`}</Typography>
        </li>
        <li className={classes.listNavItem}>
          <Cancel onClick={() => dispatch(logout())} />
        </li>
      </ul>
    </div>
  )

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.appbar}
      >
        <Toolbar>
          <div className={classes.toolbar}>
            <div className={classes.sectionLogo}>
              {useMediaQuery('(max-width: 1170px)') && (
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <div className={classes.logoContainer}>
                <img
                  className={classes.logoImg}
                  src="/assets/images/logos/inova_white.svg"
                  alt="logo"
                />
                <div>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    XP
                  </Typography>
                  <span className={classes.version}>
                    {`version ${process.env.REACT_APP_PROJECT_VERSION}`}
                  </span>
                </div>
              </div>
            </div>

            <div className={classes.menubar}>
              {sectionUser()}
            </div>
          </div>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
        </Drawer>
      </AppBar>
      {renderMenu}
      <div className={classes.childContainer}>
        <Component />
      </div>
    </div>
  )
}

export default withStyles(styles)(MainNavbar)
