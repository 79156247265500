import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import SignedInLayout from 'ui/layouts/MainNavbar'

import Login from 'ui/views/Login'
import PasswordChange from 'ui/views/PasswordChange'
import PasswordConfirmRecover from 'ui/views/PasswordConfirmRecover'
import PasswordRecover from 'ui/views/PasswordRecover'

import Home from 'ui/views/Home'

import SecuredRoute from './securedRoute'

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/password-change" exact component={PasswordChange} />
        <Route path="/password-confirm-recover" exact component={PasswordConfirmRecover} />
        <Route path="/password-recover" exact component={PasswordRecover} />

        <SecuredRoute
          path="/home"
          parent={SignedInLayout}
          child={Home}
        />

        <Route component={Login} />
      </Switch>
    </BrowserRouter>
  )
}
