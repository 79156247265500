import styled from 'styled-components'

export default styled.div`
    width: 100%;

    .filter-container {
        width: 370px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 20px;
    }

    .handle-modal, .button {
        cursor: pointer;
    }

    .button {
        padding: 10px;
        font-size: 14px;
        text-transform: uppercase;
        color: ${(props) => props.theme.palette.gray[800]};
    }

    .button:hover {
        background-color: ${(props) => props.theme.palette.gray[100]};
    }

    h1.mTitle {
        font-size: 20px;
        margin-bottom: 2rem;
        text-align: center;
    }
`