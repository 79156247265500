import React from 'react'
import _ from 'lodash'

import {
  Checkbox,
} from '@material-ui/core'

import { booleanToText, calculeTotal, formatToCurrency } from 'utils/functions'
import { FILE_TYPE } from 'utils/constants'

import Container from './styles'

// eslint-disable-next-line
const isDisableCheck = (declaration) => {
  if (declaration.country_code !== '105') return false
  if (!['LÍQUIDO', 'ILÍQUIDO'].includes(declaration.classification)) return false

  return true
}

const IrValues = ({ filesData, validTotal, equityEvolution, onDeclarationCheck, selectedItem }) => {
  if (_.isEmpty(filesData)) return <span>IR: Nenhum arquivo de Declaração</span>

  const renderTotals = () => {
    return (
      <div className="values-data-container">
        <p>
          <span>Valor Total:</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(
              calculeTotal(FILE_TYPE.IR, filesData),
              { withSymbol: true },
            )}
          </span>
        </p>
        <p>
          <span>Valor Líquido Total:</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(
              calculeTotal(FILE_TYPE.IR, filesData, { filterChecked: true }),
              { withSymbol: true },
            )}
          </span>
        </p>
        <p>
          <span>Valor Líquido Total (80%):</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(
              calculeTotal(FILE_TYPE.IR, filesData, { filterChecked: true, percentage: 80 }),
              { withSymbol: true },
            )}
          </span>
        </p>
        <p style={{ marginTop: 10 }}>
          <span>Evolução Patrimonial:</span>
          {' '}
          <span className="total-correct">
            {formatToCurrency(equityEvolution, { withSymbol: true })}
          </span>
        </p>
        <p>
          <span>Valores Corretos:</span>
          {' '}
          <span className="total-correct">
            {booleanToText(validTotal)}
          </span>
        </p>
      </div>
    )
  }

  const renderTaxpayerIdentification = (data) => {
    const taxpayerIdentification = _.get(data, 'taxpayer_identification')
    if (_.isEmpty(taxpayerIdentification)) return null

    return (
      <div className="table-container">
        <h3>Identificação</h3>

        <table>
          <tbody>
            <tr>
              <td><strong>CPF:</strong></td>
              <td><span>{taxpayerIdentification.cpf}</span></td>
            </tr>
            <tr>
              <td><strong>Nome:</strong></td>
              <td><span>{taxpayerIdentification.name}</span></td>
            </tr>
            <tr>
              <td><strong>Tipo de Declaração:</strong></td>
              <td><span>{taxpayerIdentification.type_ir}</span></td>
            </tr>
            <tr>
              <td><strong>Natureza da Ocupação:</strong></td>
              <td><span>{taxpayerIdentification.occupation_nature}</span></td>
            </tr>
            <tr>
              <td><strong>Ano Calendário:</strong></td>
              <td><span>{taxpayerIdentification.calendar_year}</span></td>
            </tr>
            <tr>
              <td><strong>Ano Exercício:</strong></td>
              <td><span>{taxpayerIdentification.exercise_year}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const renderAssetDeclaration = (data, fileIndex) => {
    const assetsDeclaration = _.get(data, 'assets_declaration')
    if (_.isEmpty(assetsDeclaration)) return null

    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Calcular</th>
              { assetsDeclaration[0].asset_group_code &&
                <th>Grupo</th>
              }
              <th>Código</th>
              <th>Código do País</th>
              <th>Descrição</th>
              <th>Classificação</th>
              <th>Valor Ano Anterior</th>
              <th>Valor Atual</th>
            </tr>
          </thead>
          <tbody>
            {_.map(assetsDeclaration, (declaration, j) => (
              <tr key={j.toString()}>
                <td>
                  <Checkbox
                    checked={declaration.checked}
                    color="primary"
                    disabled={selectedItem}
                    onChange={(e) => onDeclarationCheck(e, fileIndex, j)}
                  />
                </td>
                { declaration.asset_group_code &&
                  <td>{declaration.asset_group_code}</td>
                }
                <td>{declaration.asset_code}</td>
                <td>{`${declaration.country_code} - ${declaration.country_name}`}</td>
                <td>{declaration.asset_description}</td>
                <td>{declaration.classification}</td>
                <td>{formatToCurrency(declaration.before_year_asset_value)}</td>
                <td>{formatToCurrency(declaration.current_year_asset_value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const renderFiles = () => {
    return (
      <div className="values-files-container">
        {_.map(filesData, (data, fileIndex) => (
          <div className="values-file-container" key={fileIndex.toString()}>
            <div className="text-container">
              <p>
                <span>{`Arquivo #${fileIndex + 1}:`}</span>
                {' '}
                <span className="file-name">{data.name}</span>
              </p>
              <p>
                <span>Arquivo correto:</span>
                {' '}
                <span className="file-correct">
                  {booleanToText(data.amount_asset_code_equal_amount_asset_values)}
                </span>
              </p>
            </div>

            {renderTaxpayerIdentification(data)}
            {renderAssetDeclaration(data, fileIndex)}
          </div>
        ))}
      </div>
    )
  }

  return (
    <Container>
      <h2><ul><li>Declaração</li></ul></h2>

      <div style={{ width: '100%' }}>
        {renderTotals()}
        {renderFiles()}
      </div>
    </Container>
  )
}

export default IrValues
