import styled from 'styled-components'

export default styled.div`
    .MuiPaginationItem-root {
        border-radius: initial;
        font-weight: bold;
        font-size: 1.5rem;
    }

    .Mui-selected {
        background-color: #616161 !important;
        color: white;

        &:hover {
            background-color: #757575;
        }
    }

`