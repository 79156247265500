import React from 'react'

import Pagination from '@material-ui/lab/Pagination'
import Container from './styles'

export default ({ ...rest }) => {
  return (
    <Container>
      <Pagination {...rest} />
    </Container>
  )
}
